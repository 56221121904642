<template>
  <div>
    <a-form :model="searchParams" class="w-100 justify-content-center" layout="inline">
      <a-form-item field="title" label="名称" style="min-width: 240px; width: 40%">
        <a-input v-model="searchParams.title" placeholder="请输入名称"/>
      </a-form-item>
      <a-form-item field="tags" label="标签" style="min-width: 240px; width: 40%">
        <a-input-tag v-model="searchParams.tags" placeholder="请输入标签"/>
      </a-form-item>
      <a-form-item style="width: 12%">
        <a-button type="primary" @click="doSubmit" class="w-100">提交</a-button>
      </a-form-item>
    </a-form>
    <a-table
        :columns="columns"
        :data="datalist"
        :pagination="{pageSize: searchParams.pageSize,
        current: searchParams.current,
        total,
        showTotal: true
        }"
        @page-change="onPageChange"
    >
      <template #optional="{ record }">
        <a-space>
          <a-button type="primary" @click="doUpdate({ ...record})">修改</a-button>
          <a-button status="danger" @click="doDelete({ ...record })">删除</a-button>
        </a-space>
      </template>
    </a-table>
  </div>
</template>

<style scoped>

</style>

<script lang="ts" setup>
  import {onMounted, ref, watchEffect} from 'vue';
  import {Question, QuestionControllerService} from "../../../generated";
  import message from "@arco-design/web-vue/es/message";
  import {useRouter} from "vue-router";
  import store from "@/store";
  import accessEnum from "@/access/accessEnum";

  const total = ref(0);
  const datalist = ref([]);
  const searchParams = ref({
    pageSize: 10,
    current: 1,
    title: "",
    tags: [],
  });
  const loadData = async () => {
    let res;
    if (store.state.user.loginUser.userRole === accessEnum.ADMIN) {
      res = await QuestionControllerService.listQuestionByPageUsingPost(searchParams.value);
    } else {
      res = await QuestionControllerService.listMyQuestionVoByPageUsingPost(searchParams.value);
    }
    // console.log("题目管理", res.data)
    if (res.code === 0) {
      datalist.value = res.data.records;
      total.value = res.data.total;
    } else {
      message.error("加载失败," + res.message);
    }
  }
  // 初始化时请求数据
  onMounted(() => {
    loadData();
  });

  const columns =
      [
        {
          title: '标题',
          dataIndex: 'title',
          ellipsis: true,
        },
        {
          title: '内容',
          dataIndex: 'content',
          ellipsis: true,
        },
        {
          title: '标签',
          dataIndex: 'tags',
          ellipsis: true,
        },
        {
          title: '答案',
          dataIndex: 'answer',
          ellipsis: true,
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          ellipsis: true,
        },
        {
          title: '修改时间',
          dataIndex: 'updateTime',
          ellipsis: true,
        },
        {
          title: '操作',
          slotName: 'optional'
        },
      ];

  const doDelete = async (question: Question) => {
    const res = await QuestionControllerService.deleteQuestionUsingPost({id: question.id});
    if (res.code === 0) {
      message.success("删除成功");
      loadData();
    } else {
      message.error("删除失败", res.message);
    }
  }

  const router = useRouter();

  const doUpdate = (question: Question) => {
    router.push({
      path: "/update/question",
      query: {
        id: question.id
      }
    })
  }

  const onPageChange = (page: number) => {
    searchParams.value = {
      ...searchParams.value,
      current: page
    }
  }

  // 监听数据变化
  watchEffect(() => {
    loadData();
  });

  const doSubmit = () => {
    // 这里需要重置搜索页号
    searchParams.value = {
      ...searchParams.value,
      current: 1,
    };
  };

</script>

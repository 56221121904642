<template>
  <div class="container mt-5" style="width: 100vw;">
    <div class="row w-100 d-flex m-3 align-items-stretch rounded-3 p-3" style="height: 75vh;">
      <!-- 左侧输入参与者 -->
      <div class="col-md-4 ">
        <div class="card shadow">
          <h1 class="card-header">参与者名单</h1>
          <div class="card-body border-0 d-flex flex-column align-items-center justify-content-center p-0">
            <textarea v-model="participantsInput" class="rounded-0 form-control h-100" placeholder="每行一个参与者"
                      rows="10"  style="resize: none;"></textarea>
          </div>
        </div>
      </div>
      <!-- 中间抽奖部分 -->
      <div class="col-md-4">
        <div class="card shadow">
          <h1 class="card-header">幸运大抽奖</h1>
          <div class="card-body d-flex flex-column align-items-center justify-content-center">
            <h3 id="xiaoFanTuan" class="my-4 text-bold">{{ luckyDog }}</h3>
            <button class="btn btn-primary mt-3" @click="toggleLottery">{{ buttonText }}</button>
          </div>
        </div>
      </div>
      <!-- 右侧中奖者记录 -->
      <div class="col-md-4 ">
        <div class="card shadow">
          <h1 class="card-header">中奖者名单</h1>
          <div class="card-body p-0 d-flex flex-column align-items-center justify-content-center">
            <span v-for="(winner, index) in winners" :key="index" class="p-1 list-group-item w-100"><span>第{{index+1}}位幸运儿：</span> {{ winner }}</span> <!-- 使用插值语法 -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts" setup>
  import {ref, watch} from 'vue';
  import message from "@arco-design/web-vue/es/message";

  // 响应式数据
  const luckyDog = ref('待定');
  const buttonText = ref('点击抽奖');
  let timer: number;
  let idx: number;
  const participantsInput = ref('');
  const participants = ref<string[]>([]);
  const winners = ref<string[]>([]);

  // 更新参与者列表
  const updateParticipants = () => {
    participants.value = participantsInput.value.split('\n').filter(participant => participant.trim() !== '');
  };

  // 开始抽奖
  const startLottery = () => {
    // 如果空了
    if (participants.value.length === 0) {
      message.error('请输入参与者');
      return;
    }
    clearInterval(timer!);
    timer = setInterval(() => {
      idx = Math.floor(Math.random() * participants.value.length);
      luckyDog.value = participants.value[idx];
    }, 30);
    buttonText.value = '停止滚动';
  };

  // 停止抽奖
  const stopLottery = () => {
    clearInterval(timer!);
    buttonText.value = '继续抽奖';
    // 将当前幸运儿添加到已中奖列表
    winners.value.push(luckyDog.value);
    // 去重
    participants.value.splice(idx, 1);
    // 继续
    startLottery();
  };

  // 切换抽奖状态
  const toggleLottery = () => {
    if (timer) {
      stopLottery();
    } else {
      startLottery();
    }
  };

  document.onkeyup = (e) => {
    if (e.key === 'a' || e.key === 'A') {
      toggleLottery();
    }
  };

  // 监听参与者输入变化
  watch(participantsInput, () => {
    updateParticipants();
  });
</script>

<style scoped>
  .row {
    display: flex;
    align-items: stretch; /* 使子元素等高 */
  }

  .bg-left {
    background-color: #f8d7da; /* 左侧背景颜色 */
  }

  .bg-middle {
    background-color: #d4edda; /* 中间背景颜色 */
  }

  .bg-right {
    background-color: #fff3cd; /* 右侧背景颜色 */
  }

  h1 {
    text-align: center;
    font-size: 55px;
    color: #f26522;
  }

  h2 {
    text-align: center;
    font-size: 100px;
    color: #60bfb6;
  }

  button {
    text-align: center;
    font-size: 30px;
    color: white;
  }

  .container {
    max-width: 1200px;
  }

  .card {
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 100%; /* 使卡片高度占满父容器 */
  }

  .card-header {
    background-color: #f26522;
    color: white;
  }

  .card-body {
    background-color: #f9f9f9;
  }

  .list-group-item {
    background-color: #60bfb6;
    color: white;
  }
</style>


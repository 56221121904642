<script lang="js" setup>
  import {onMounted} from 'vue';
  import FooterInfo from "@/components/FooterInfo.vue";
  import {useRouter} from "vue-router";

  // 设置标题
  document.title = '算法探索者';

  onMounted(() => {
    const scrollers = document.querySelectorAll(".scroller");

    // 如果用户没有选择减少运动，则添加动画
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }

    function addAnimation() {
      scrollers.forEach((scroller) => {
        // 为每个 `.scroller` 添加 data-animated="true"
        scroller.setAttribute("data-animated", "true");

        // 将 `.scroller-inner` 内的元素转换为数组
        const scrollerInner = scroller.querySelector < HTMLElement > (".scroller__inner");
        if (scrollerInner) {
          const scrollerContent = Array.from(scrollerInner.children);

          // 克隆每个元素并添加 aria-hidden
          scrollerContent.forEach((item) => {
            const duplicatedItem = item.cloneNode(true);
            duplicatedItem.setAttribute("aria-hidden", "true");
            scrollerInner.appendChild(duplicatedItem);
          });
        }
      });
    }
  });
  onMounted(() => {
    const header = document.querySelector('header');
    const img = document.querySelector('.img');
    let scrollDistance = 0;
    let requestId = null;

    function updateHeaderClipPath() {
      const clipPathValue = `polygon(0 0, 100% 0%, 100% ${(scrollDistance <= 600) ? 100 - ((scrollDistance / 600) * 60) : 75}%, 0 100%)`;
      header.style.clipPath = clipPathValue;
      const scaleValue = 1 + ((scrollDistance / 600) * 1);
      img.style.transform = `scale(${scaleValue})`;
      const opacityValue = (scrollDistance / 600);
    }

    function scrollHandler(event) {
      if (event.deltaY < 0) {
        scrollDistance = Math.max(0, scrollDistance + event.deltaY);
      } else {
        scrollDistance = Math.min(600, scrollDistance + event.deltaY);
      }
      if (!requestId) {
        requestId = window.requestAnimationFrame(() => {
          updateHeaderClipPath();
          requestId = null;
        });
      }
    }

    window.addEventListener('wheel', scrollHandler);
  });

  onMounted(() => {
    const fault = document.getElementById("fault");
    const page = document.getElementById("page");
    const faulttext = {
      player: {},
      texts: [],
      init() {
        this.texts = [...document.getElementsByClassName('faulttext')];
      },
      fault() {
        clearInterval(this.player);
        setTimeout(() => {
          clearInterval(this.player);
          this.texts.forEach((text) => {
            text.classList.remove("faulttext_fault");
            text.style.transform = '';
            text.style.clipPath = '';
          });
        }, 1000)
        this.player = setInterval(() => {
          this.texts.forEach((text) => {
            text.classList.add("faulttext_fault");
            text.style.transform = `translate(${Math.random() * 60 - 30}%,${Math.random() * 60 - 30}%)`;
            let x = Math.random() * 100;
            let y = Math.random() * 100;
            let h = Math.random() * 50 + 50;
            let w = Math.random() * 40 + 10;
            text.style.clipPath = `polygon(${x}% ${y}%, ${x + w}% ${y}%, ${x + w}% ${y + h}%, ${x}% ${y + h}%)`
          })
        }, 30);
      }
    };
    faulttext.init();
    fault.onclick = async () => {
      faulttext.fault();
      // 等待0.7s
      await new Promise(resolve => setTimeout(resolve, 700));
      page.style.display = "block";
      fault.style.display = "none";
    }
  })
  const router = useRouter();
  const toRegister = () => {
    router.push({
      path: "/",
    });
  }
</script>

<template>
  <div id="fault">
    <div class="overlay"></div>
    <div class="faulttext">AC之火，<br/> 可以燎原</div>
  </div>
  <div id="page" style="display: none">
    <div class="shell">
      <header>
        <div class="img"></div>
      </header>
      <div class="main">
        <main>
          <article>
            <div class="multicol">
              <h1>Algorithm Explorer</h1>
              <h2>AC's Spark</h2>
              <!--介绍算法-->
              <p>
                An algorithm is a well-defined procedure or set of rules designed to solve a specific problem or
                perform
                a
                particular task. It is a fundamental concept in computer science, mathematics, and many other fields.
                Algorithms take input data, process it through a series of steps, and produce an output that solves
                the
                given problem. It is a set of instructions that can be executed by a computer to solve a
                problem or perform a task.
                It is a step-by-step process that involves inputs, outputs, and intermediate steps to produce a
                desired output.Overall I love algorithm.
              </p>
            </div>

            <img alt="" src="../assets/img_1.png">
            <div class="multicol">
              <p>The International Collegiate Programming Contest (ICPC) is a prestigious annual multi-tiered
                 programming competition among the universities of the world. Organized by the Association for Computing
                 Machinery (ACM), ICPC challenges teams of university students to solve complex algorithmic problems
                 under strict time constraints. The competition fosters teamwork, problem-solving skills, and the
                 ability to write correct and efficient code.The ICPC was first held in 1970 at Texas A&M University.
                 Since then, it has grown to become one of the most respected programming competitions in the world,
                 attracting participants from over 3,000 universities across more than 100 countries.
                 It has become a global phenomenon, with participants from around the world competing in
                 various regional and national competitions.
              </p>
            </div>
            <br/>
            <div class="card my-2">
              <img alt="" class="card-img-top" src="../assets/icpc.png">
              <div class="card-body d-flex center">
                <button class="btn btn-primary my-4 w-50 shadow register-btn" @click="toRegister">Home</button>
              </div>
            </div>
          </article>
        </main>
      </div>
    </div>
    <div class="scroller my-0" data-speed="fast">
      <ul class="tag-list scroller__inner">
        <li> 我受够了等待</li>
        <li> 你所谓的安排</li>
        <li> 说的未来到底多久才来</li>
        <li> 总是要来不及</li>
        <li> 才知道我可爱</li>
        <li> 我想依赖</li>
        <li> 而你却都不在</li>
        <li> 应该开心的地带</li>
        <li> 你给的全是空白</li>
        <li> 一个人假日发呆</li>
        <li> 找不到人陪我看海</li>
        <li> 我在幸福的门外</li>
        <li> 却一直都进不来</li>
        <li> 你累积给的伤害</li>
        <li> 我是真的很难释怀</li>
        <li>终于看开</li>
        <li>爱回不来</li>
        <li>而你总是太晚明白</li>
        <li>最后才把话说开</li>
        <li>哭着求我留下来</li>
        <li>终于看开 爱回不来</li>
        <li>我们面前太多阻碍</li>
        <li>你的手却放不开</li>
        <li>宁愿没出息 求我别离开</li>
        <li>你总是要我乖</li>
        <li>慢慢计划将来</li>
        <li> 我的眼泪却一直掉下来</li>
        <li> 过去怎么交代</li>
        <li> 你该给的信赖</li>
        <li> 被你亲手缓缓推入悬崖</li>
        <li> 从我脸上的苍白</li>
        <li> 看到记忆慢下来</li>
        <li> 过去甜蜜在倒带</li>
        <li> 只是感觉已经不在</li>
        <li> 而我对你的期待</li>
        <li> 被你一次次摔坏</li>
        <li> 已经碎成太多块</li>
        <li> 要怎么拼凑跟重来</li>
        <li> 终于看开 爱回不来</li>
        <li> 而你总是太晚明白</li>
        <li> 最后才把话说开</li>
        <li> 哭着求我留下来</li>
        <li> 终于看开 爱回不来</li>
        <li> 我们面前太多阻碍</li>
        <li> 你的手却放不开</li>
        <li> 宁愿没出息 求我别离开</li>
        <li> 终于看开 爱回不来</li>
        <li> 而你总是太晚明白</li>
        <li> 最后才把话说开</li>
        <li> 哭着求我留下来</li>
        <li> 终于看开 爱回不来</li>
        <li> 我们面前太多阻碍</li>
        <li> 你的手却放不开</li>
        <li> 宁愿没出息 求我别离开</li>
      </ul>
    </div>
    <div class="scroller" data-speed="fast">
      <ul class="tag-list scroller__inner">
        <li>Dijsktra</li>
        <li>KMP</li>
        <li>Tire-Tree</li>
        <li>Bfs</li>
        <li>Dfs</li>
        <li>Prim</li>
        <li>Floyd</li>
        <li>Kruskal</li>
        <li>String-Hash</li>
        <li>AC-Automata</li>
        <li>Boyer–Moore</li>
        <li>Dynamic-Programming</li>
        <li>Greedy</li>
        <li>Segment-Tree</li>
        <li>MD5</li>
        <li>Simulated-Annealing</li>
        <li>A*</li>
        <li>Bellman-Ford</li>
        <li>Queue</li>
        <li>Stack</li>
        <li>Heap</li>
        <li>Map</li>
        <li>Set</li>
        <li>Dijsktra</li>
        <li>KMP</li>
        <li>Tire-Tree</li>
        <li>Bfs</li>
        <li>Dfs</li>
        <li>Prim</li>
        <li>Floyd</li>
        <li>Kruskal</li>
        <li>String-Hash</li>
        <li>AC-Automata</li>
        <li>Boyer–Moore</li>
        <li>Dfs</li>
        <li>Prim</li>
        <li>Floyd</li>
        <li>Kruskal</li>
        <li>String-Hash</li>
        <li>AC-Automata</li>
        <li>Boyer–Moore</li>
        <li>Dynamic-Programming</li>
        <li>Greedy</li>
        <li>Segment-Tree</li>
        <li>MD5</li>
        <li>Simulated-Annealing</li>
        <li>A*</li>
        <li>Bellman-Ford</li>
        <li>Queue</li>
        <li>Stack</li>
        <li>Heap</li>
        <li>Map</li>
        <li>Set</li>
      </ul>
    </div>
    <div class="w-100 d-flex center">
      <FooterInfo/>
    </div>
  </div>
</template>

<style lang="css" scoped>
  .scroller {
    width: 100%;
  }

  .register-btn {
    height: 4rem;
    font-size: 2rem;
  }

  #fault {
    width: 100%;
    height: 100%;
    background: url("../assets/img_3.png");
    background-size: cover;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* 黑色背景，50% 透明度 */
  }

  .body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #000;
    overflow: hidden;
  }

  .faulttext {
    position: absolute;
    font-size: 5rem;
    color: #fff;
    letter-spacing: 0.5rem;
    user-select: none;
  }

  .faulttext_fault::after,
  .faulttext_fault::before {
    content: "CONTEXT";
    position: absolute;
    left: 0;
    top: 0;
    mix-blend-mode: screen;
  }

  .faulttext_fault::after {
    color: #ff0000;
    transform: translateX(2%);
  }

  .faulttext_fault::before {
    color: #0000ff;
    transform: translateX(-2%);
  }

  * {
    padding: 0;
    margin: 0;
  }

  .shell {
    width: 100%;
    display: flex;
    flex-direction: column;

  }

  header {
    width: 100%;
    height: 700px;
    overflow: hidden;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%);
    transition: clip-path 0.5s ease;
    position: relative;
  }

  .img {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    background: url(../assets/img.png);
    background-size: cover;
    transform: scale(1);
    transition: transform 0.5s ease;
  }

  .main {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
  }

  .main h2 {
    font-size: 50px;
    margin-bottom: 40px;
  }

  .main span {
    padding: 0 100px;
    font-size: 30px;
  }


  @import url('https://fonts.googleapis.com/css?family=Lato:700,900');

  @font-face {
    font-family: Arno Pro;
    src: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/ArnoPro-Regular.otf);
    font-style: normal;
  }

  @font-face {
    font-family: Arno Pro;
    src: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/ArnoPro-Italic.otf);
    font-style: italic;
  }

  p {
    font-family: Arno Pro, serif;
    font-size: 1.4rem;
    line-height: 1.4;
    margin: 0;
  }

  p.credits {
    font-style: italic;
    text-indent: 0 !important;
    margin: 1rem 0;
  }

  p a {
    font-weight: bolder;
    color: #000;
    text-decoration: none;
  }

  @supports (font-variant-caps: small-caps) {
    .multicol:first-of-type p:first-of-type {
      font-variant-caps: small-caps;
    }
  }

  p:not(:first-of-type) {
    text-indent: 1.4rem;
  }

  .multicol {
    column-count: 4;
    column-gap: 2rem;
  }

  .multicol + img {
    margin: 2rem 0;
  }

  article {
    width: 80%;
    margin: 0 auto 4rem;
  }

  article img {
    width: 100%;
  }

  article header {
    margin-bottom: 2.5rem;
    padding-bottom: 4rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.8);
  }

  article h1 {
    margin-top: 0;
    font-size: 3rem;
  }

  article h2 {
    break-after: column;
    font-size: 1.5rem;
  }

  h1,
  h2 {
    font-family: Lato, sans-serif;
  }

  @media all and (max-width: 1600px) {
    .multicol {
      column-count: 3;
    }
  }

  @media all and (max-width: 1000px) {
    .multicol {
      column-count: 2;
    }
  }

  @media all and (max-width: 800px) {
    article h2 {
      break-after: avoid;
    }

    .multicol {
      column-count: 1;
    }
  }

  @media all and (max-width: 600px) {
    body {
      margin: 0;
    }

    article {
      width: 100%;
    }

    .multicol {
      padding: 0 1rem;
    }

    article header {
      margin-bottom: 2.5rem;
      padding-bottom: 0;
      border-bottom: none;
    }

    h1 {
      margin-bottom: 0;
    }
  }

  .banner {
    background: linear-gradient(to top right, #3568f2 49.7%, #fff 50%);
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .banner .title {
    text-align: center;
    transition: transform 1s;
  }

  .banner .title:hover {
    transform: translateX(-110px);
  }

  .scroller__inner {
    width: 100%;
    /* padding-block: 1rem; */
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .scroller[data-animated="true"] {
    overflow: hidden;
    mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
    -webkit-mask: -webkit-linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
    mask-size: 100% 100%;
    -webkit-mask-size: 100% 100%;
  }

  .scroller[data-animated="true"] .scroller__inner {
    width: max-content;
    flex-wrap: nowrap;
    -webkit-animation: scroll var(--_animation-duration, 40s) var(--_animation-direction, forwards) linear infinite;
    animation: scroll var(--_animation-duration, 40s) var(--_animation-direction, forwards) linear infinite;
  }

  .scroller[data-direction="right"] {
    --_animation-direction: reverse;
  }

  .scroller[data-direction="left"] {
    --_animation-direction: forwards;
  }

  .scroller[data-speed="fast"] {
    --_animation-duration: 20s;
  }

  .scroller[data-speed="slow"] {
    --_animation-duration: 60s;
  }

  @-webkit-keyframes scroll {
    to {
      transform: translate(calc(-50% - 0.5rem));
    }
  }

  @keyframes scroll {
    to {
      transform: translate(calc(-50% - 0.5rem));
    }
  }

  /* general styles */

  :root {
    --clr-neutral-100: hsl(0, 0%, 100%);
    --clr-primary-100: hsl(205, 15%, 58%);
    --clr-primary-400: hsl(215, 25%, 27%);
    --clr-primary-800: hsl(217, 33%, 17%);
    --clr-primary-900: hsl(218, 33%, 9%);
  }

  html {
    color-scheme: dark;
  }

  body {
    display: grid;
    min-block-size: 100vh;
    place-content: center;
    font-family: system-ui;
    font-size: 1.125rem;
    background-color: var(--clr-primary-800);
  }

  .tag-list {
    margin: 0;
    padding-inline: 0;
    list-style: none;
  }

  .tag-list li {
    padding: 1rem;
    background: var(--clr-primary-400);
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem -0.25rem var(--clr-primary-900);
  }
</style>
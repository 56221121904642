import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "addQuestion",
  style: {"height":"90vh"}
}

import {onMounted, ref} from 'vue';
  import MDEditor from "@/components/MDEditor.vue";
  import {Message} from "@arco-design/web-vue";
  import {PostControllerService} from "../../../generated";
  import store from "@/store";
  import {useRoute, useRouter} from "vue-router";
  import message from "@arco-design/web-vue/es/message";
  import ACCESS_ENUM from "@/access/accessEnum";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'UpdatePostView',
  setup(__props) {

  const router = useRouter();


  const form = ref({
    content: '',
    tagList: [],
    title: ''
  });
  const route = useRoute();
  const id = route.query.id;
  const loadData = async () => {
    if (!id) {
      Message.error("id参数不可为空");
    }
    const res = await PostControllerService.getPostVoByIdUsingGet(id as any);
    if (res.code == 0) {
      form.value = res.data as any;
    } else {
      Message.error("获取数据失败，请查看控制台" + res.message)
    }
  }
  onMounted(async () => {
    const loginUser = store.state.user.loginUser;
    const postUserId = await PostControllerService.getPostVoByIdUsingGet(id as any);
    if (loginUser.userRole === ACCESS_ENUM.ADMIN || loginUser.id === postUserId.data?.user?.id) {
      await loadData();
    }else{
      await router.push({
        path: '/noAuth',
        replace: true
      });
    }
  })
  const doSubmit = async () => {
    if (!id) {
      message.error("id为空");
      return;
    }
    // 获取权限
    const userRole = store.state.user.userRole;
    let res;
    if (userRole === ACCESS_ENUM.ADMIN) {
      res = await PostControllerService.updatePostUsingPost({
        ...form.value,
        tags: form.value.tagList,
        id: id as any
      });
    } else {
      res = await PostControllerService.editPostUsingPost({
        ...form.value,
        tags: form.value.tagList,
        id: id as any
      });
    }
    if (res.code == 0) {
      Message.success("修改成功")
      setTimeout(() => {
        router.push({
          path: '/view/post/' + id,
          replace: true
        })
      }, 200);
    } else {
      Message.error("添加失败" + res.message)
    }
  }

  const onContentChange = (value: string) => {
    form.value.content = value;
  };
  setInterval(() => {
    const images = document.querySelectorAll('.medium-zoom-image--opened');
    for (let i = 0; i < images.length; i++) {
      (images[i] as HTMLElement).style.zIndex = String(100); // 示例操作，根据实际需求调整
    }
  }, 300); // 假设每秒执行一次
  onMounted(() => {
    const bytemd = document.querySelector('.bytemd');
    if (bytemd instanceof HTMLElement) {
      bytemd.style.height = "60vh";
    }
  });

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_tag = _resolveComponent("a-input-tag")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[3] || (_cache[3] = _createElementVNode("h1", { style: {"text-align":"center"} }, "修改帖子", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_form, {
        model: form.value,
        "auto-label-width": "",
        class: "h-100"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            field: "title",
            label: "标题"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                modelValue: form.value.title,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.title) = $event)),
                placeholder: "输入标题"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            field: "content",
            label: "帖子内容"
          }, {
            default: _withCtx(() => [
              _createVNode(MDEditor, {
                id: "md",
                "handle-change": onContentChange,
                value: form.value.content,
                style: {"width":"100%","z-index":"999"}
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            field: "tags",
            label: "标签"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input_tag, {
                modelValue: form.value.tagList,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.tagList) = $event)),
                "allow-clear": "",
                placeholder: "选择标签"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { style: {"display":"flex","width":"100%","align-items":"center","justify-content":"center !important"} }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                "html-type": "submit",
                type: "primary",
                onClick: doSubmit
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("确认发布")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model"])
    ])
  ], 64))
}
}

})
<template>
  <h1 style="text-align: center">创建题目</h1>
  <div class="center">
    <a-button size="large" status="success" type="dashed" @click="insert">模板插入</a-button>
  </div>
  <div id="addQuestion">
    <a-form :model="form" auto-label-width>
      <a-form-item field="title" label="标题">
        <a-input v-model="form.title" placeholder="输入标题"/>
      </a-form-item>
      <a-form-item field="content" label="题目内容">
        <MDEditor id="md1"
                  :handle-change="onContentChange"
                  :value="form.content"
                  style="width: 100%"/>
      </a-form-item>
      <a-form-item field="answer" label="官方题解">
        <MDEditor id="md2" :handle-change="onAnswerChange" :value="form.answer" style="width: 100%"/>
      </a-form-item>
      <a-form-item field="tags" label="标签">
        <a-input-tag v-model="form.tags" allow-clear placeholder="选择标签"/>
      </a-form-item>
      <a-form-item :content-flex="false" :merge-props="false" label="案例">
        <a-form-item v-for="(judgeCaseItem, index) in form.judgeCase"
                     :key="index"
                     :label="`案例 ${index}`"
                     field="judgeCase"
                     no-style
        >
          <a-form-item
              :key="index"
              :field="`form.judgeCase[${index}].input`"
              :label="`输入案例 ${index}`"
          >
            <a-textarea v-model="judgeCaseItem.input"></a-textarea>
          </a-form-item>
          <a-form-item
              :key="index"
              :field="`form.judgeCase[${index}].output`"
              :label="`输出案例 ${index}`"
          >
            <a-textarea v-model="judgeCaseItem.output"></a-textarea>
          </a-form-item>
          <div
              style="display: flex; margin-top: 12px; margin-bottom: 30px; align-items: center;justify-content: center">
            <a-button status="danger" @click="handleDelete(index)">Delete</a-button>
          </div>
        </a-form-item>
        <div style="margin-top: 33px;display: flex; align-items: center;justify-content: center">
          <a-button status="success" type="outline" @click="handleAdd">新增测试用例</a-button>
        </div>
      </a-form-item>
      <a-form-item :content-flex="false" :merge-props="false" label="题目限制">
        <a-space direction="vertical">
          <a-form-item field="timeLimit" label="时间限制(ms)" validate-trigger="input">
            <a-input-number v-model="form.judgeConfig.timeLimit" min="400" mode="button"
                            placeholder="输入时间限制(ms)..."
                            size="large"/>
          </a-form-item>
          <a-form-item field="memoryLimit" label="空间限制(kb)" validate-trigger="input">
            <a-input-number v-model="form.judgeConfig.memoryLimit" min="32768" mode="button"
                            placeholder="输入空间限制(ms)..." size="large"/>
          </a-form-item>
          <a-form-item field="stackLimit" label="堆栈限制(kb)" validate-trigger="input">
            <a-input-number v-model="form.judgeConfig.stackLimit" min="8192" mode="button"
                            placeholder="输入栈限制(kb)..."
                            size="large"/>
          </a-form-item>
        </a-space>
      </a-form-item>
      <a-form-item style="display: flex; width: 100%; align-items: center; justify-content: center !important;">
        <a-button html-type="submit" type="primary" @click="doSubmit">Submit</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<style>
  #addQuestion {
    display: flex;
    justify-content: center;
  }

  .center {
    display: flex;
    justify-content: center;
    width: 100%;
    align-content: center;
    margin-bottom: 40px;
  }

  .arco-form-item-content-flex {
    //display: block !important;
    align-items: normal !important;
    justify-content: flex-start;
  }

  .arco-form-item-content-flex {
    justify-content: center !important;
  }
</style>
<script lang="ts" setup>
  import {onMounted, ref} from 'vue';
  import MDEditor from "@/components/MDEditor.vue";
  import {Message} from "@arco-design/web-vue";
  import {QuestionControllerService} from "../../../generated";
  import store from "@/store";
  import checkAccess from "@/access/checkAccess";
  import ACCESS_ENUM from "@/access/accessEnum";
  import {useRouter} from "vue-router";

  const contentText =
      "首先你是一只猫，本题目要求读入$2$个整数$A$和$B$，然后输出它们的和。\n\n"
      + "如图所示，这是一只猫猫。(图片可以直接复制黏贴)\n"
      + "![image.png](http://image.niwenjia.cn/bcb2ceeb70a74c04a1313a5191d039d9.png \"image.png\")\n\n"
      + "### 输入格式:"
      + "\n请在这里写输入格式。例如：输入在一行中给出$2$个绝对值不超过$10$的整数$A$和$B$。\n"
      + "```in\n 1 2\n ```\n"
      + " \n### 输出格式:\n" + "在这里给出相应的输出。例如：\n"
      + "```out\n miao miao miao\n```\n"

  const ansText =
      "# 题意\n> 首先猫猫很聪明\n> 其次猫猫不会讲人话\n\n" + "" +
      "# 思路\n字符串模拟猫猫讲话\n"
      + "# Code\n"
      + "```cpp"
      + "\n＃include 《i0stream》\n"
      + "long long mian()\n"
      + "{\n    int A，B;\n"
      + "    cin>>A>>B；\n\n"
      + "    for{int i = 1; i <= (a+b); i加加}\n"
      + "    (\n"
      + "        cout👈👈“mia”;\n"
      + "        if(i != (a+b) ) cout ←← ' ';\n"
      + "    )\n"
      + "\n    return O;\n"
      + "}\n"
      + "```"

  const form = ref({
    title: "",
    content: "",
    tags: ["简单"],
    answer: "",
    submitNum: 0,
    acceptedNum: 0,
    judgeConfig: {
      memoryLimit: 262144,
      stackLimit: 8192,
      timeLimit: 800
    },
    judgeCase: [
      {
        input: "",
        output: ""
      },
    ],
  });

  const handleAdd = () => {
    if (form.value) {
      form.value.judgeCase.push({
        input: '',
        output: ''
      })
    }
  };
  const handleDelete = (index: number) => {
    if (form.value) {
      form.value.judgeCase.splice(index, 1)
    }
  }
  const doSubmit = async () => {
    if (form.value.title === '') {
      Message.error("标题不能为空")
      return
    }
    if (form.value.content === '') {
      // console.log("form.value", form.value.content)
      Message.error("内容不能为空")
      return
    }
    if (form.value.tags.length === 0) {
      Message.error("标签里面至少要有个难度吧~")
      return
    }
    if (form.value.answer === '') {
      Message.error("题目解析不能为空")
      return
    }
    if (!form.value.judgeConfig.timeLimit) {
      Message.error("时间限制不能为空")
      return
    } else if (form.value.judgeConfig.timeLimit < 400) {
      Message.error("时间限制不能小于400ms")
      return
    }
    if (!form.value.judgeConfig.memoryLimit) {
      Message.error("空间限制不能为空")
      return
    } else if (form.value.judgeConfig.memoryLimit < 32768) {
      Message.error("空间限制不能小于32Mb")
      return
    }
    if (!form.value.judgeConfig.stackLimit) {
      Message.error("堆栈限制不能为空")
      return
    } else if (form.value.judgeConfig.stackLimit < 8192) {
      Message.error("堆栈限制不能小于8Mb")
      return
    }
    // form.value.tags.push(store.state.user?.loginUser?.userName ?? '');
    const res = await QuestionControllerService.addQuestionUsingPost(form.value);
    if (res.code == 0) {
      Message.success("添加成功")
      // 跳转
      setTimeout(() => {
        window.location.href = "/view/question/"+res.data;
      }, 200)
    } else {
      Message.error("添加失败" + res.message)
    }
  }

  const onContentChange = (value: string) => {
    form.value.content = value;
    // console.log("debug", value)
    // alert(1)
  };

  const onAnswerChange = (answer: string) => {
    form.value.answer = answer
  };

  // const rules = {
  //   timeLimit: [
  //     {
  //       required: true,
  //       message: 'timeLimit is required',
  //     },
  //   ],
  //   memoryLimit: [
  //     {
  //       required: true,
  //       message: 'memoryLimit is required',
  //     },
  //   ],
  //   stackLimit: [
  //     {
  //       required: true,
  //       message: 'stackLimit is required',
  //     }
  //   ]
  // }
  const insert = () => {
    form.value.content = contentText;
    form.value.answer = ansText;
  }
  const router = useRouter();
  onMounted( ()=>{
    const loginUser = store.state.user.loginUser;
    if(!checkAccess(loginUser, ACCESS_ENUM.ADMIN)){
      router.push({
        path: '/noAuth',
        replace: true
      });
    }
  })

  setTimeout(() => {
    const mdList = document.getElementsByClassName("bytemd bytemd-split");
    if (mdList[0] && mdList[1]) {
      (mdList[0] as HTMLElement).addEventListener("click", () => {
        (mdList[0] as HTMLElement).style.zIndex = '2';
        // Message.success("z-index修改")
      });
      (mdList[1] as HTMLElement).addEventListener("click", () => {
        (mdList[1] as HTMLElement).style.zIndex = '2';
        // Message.success("z-index修改")
      });
    }
  }, 0);

</script>

<style scoped>
  #addQuestion {
    /* 隐藏滚动条 */

    ::-webkit-scrollbar {
      display: none;
    }
  }
</style>
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { id: "MineView" }
const _hoisted_2 = {
  id: "banner",
  class: "container text-center p-4 shadow",
  style: {"min-width":"80%"}
}
const _hoisted_3 = { class: "row align-items-center" }
const _hoisted_4 = { class: "col-auto d-flex" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "col-auto align-middle text-center" }
const _hoisted_7 = { class: "name" }
const _hoisted_8 = { class: "intro" }
const _hoisted_9 = { class: "intro" }
const _hoisted_10 = { class: "row mt-2" }
const _hoisted_11 = { class: "col-2" }
const _hoisted_12 = { class: "details-content" }
const _hoisted_13 = { class: "col-2" }
const _hoisted_14 = { class: "details-content" }
const _hoisted_15 = { class: "w-100 container text-center p-2" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "col-12" }
const _hoisted_18 = { class: "input-group my-3 w-100 mx-auto" }
const _hoisted_19 = { class: "d-flex container justify-content-center" }
const _hoisted_20 = { class: "my-3 w-75 text-center" }
const _hoisted_21 = { class: "card" }
const _hoisted_22 = { class: "container p-2" }
const _hoisted_23 = ["onClick"]
const _hoisted_24 = { key: 0 }
const _hoisted_25 = { key: 0 }

import store from "@/store";
  import {
    PostControllerService,
    PostQueryRequest,
    PostVO,
    Question,
    QuestionControllerService
  } from "../../../generated";
  import {onMounted, ref} from "vue";
  import message from "@arco-design/web-vue/es/message";
  import formatDate from "../../access/Date";
  import {useRouter} from "vue-router";
  import {IconEdit, IconUser} from '@arco-design/web-vue/es/icon';

  
export default /*@__PURE__*/_defineComponent({
  __name: 'MineView',
  setup(__props) {

  const myPosts = ref<PostVO[]>([]);
  const acCount = ref(0);
  const tableRef = ref();
  const uid = store.state.user.loginUser.id;
  let total = ref(0);
  const router = useRouter();
  const columns = [
    {
      title: "标题",
      slotName: "title",
      ellipsis: true,
    },
    {
      title: "创建时间",
      slotName: "createTime",
    },
    {
      title: '上次修改时间',
      slotName: 'updateTime',
    },
    {
      title: '标签',
      slotName: "tagList",
      ellipsis: true,
    },
    {
      title: '操作',
      slotName: 'optional'
    },
  ];
  const searchParams = ref<PostQueryRequest>({
    searchText: "",
    tags: [],
    pageSize: 8,
    current: 1,
    userId: uid,
  });
  const isLoading = ref(false);
  const doSearch = async () => {
    searchParams.value = {
      ...searchParams.value,
      current: 1
    }
    await loadData();
    if (myPosts.value?.length == 0) {
      // message.info('没有找到');
    }
  };
  const loadData = async () => {
    isLoading.value = true;
    const acCountRes = await QuestionControllerService.getAcCountByUserIdUsingGet(uid);
    if (acCountRes.code === 0) {
      acCount.value = acCountRes.data ?? 0;
    } else {
      message.error("加载失败，" + acCountRes.message);
    }
    const res = await PostControllerService.listMyPostVoByPageUsingPost(searchParams.value);
    if (res.code === 0) {
      myPosts.value = res.data.records;
      total.value = res.data.total;
    } else {
      message.error("加载失败，" + res.message);
    }
    isLoading.value = false;
  };

  const onPageChange = async (page: number) => {
    // message.info("page="+page)
    searchParams.value = {
      ...searchParams.value,
      current: page
    }
    await loadData();
  }

  const toPostPage = (post: PostVO) => {
    router.push({
      path: `/view/post/${post.id}`,
    });
  }

  onMounted(async () => {
    await loadData();
    // 监听回车
    // fixme: 应该只监听当前窗口 
    window.addEventListener('keyup', (event) => {
      // Message.info("keyup: " + event.key);
      if (event.key === 'Enter') {
        doSearch();
      }
    });
  });

  const doDelete = async (post: PostVO) => {
    const res = await PostControllerService.deletePostUsingPost({id: post.id});
    if (res.code === 0) {
      message.success("删除成功");
      await loadData();
      await router.push({
        path: "/mine",
      });
    } else {
      message.error("删除失败", res.message);
    }
  }

  const doUpdate = (question: Question) => {
    router.push({
      path: "/update/post",
      query: {
        id: question.id
      }
    })
  }

  const toUpdate = () => {
    router.push({
      path: "/update/mine",
    })
  }

  const upLoadNewAvatar = () => {
    router.push({
      path: "/update/mine",
    })
  }

  // 跳转到发布帖子页面
  const toAddPost = () => {
    router.push('/add/post');
  }

return (_ctx: any,_cache: any) => {
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_empty = _resolveComponent("a-empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_a_avatar, {
            size: 120,
            style: { backgroundColor: '#14C9C9' },
            class: "bg-primary-subtle shadow",
            shape: "square",
            onClick: upLoadNewAvatar
          }, {
            "trigger-icon": _withCtx(() => [
              _createVNode(_unref(IconEdit), { size: 15 })
            ]),
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: _unref(store).state.user?.loginUser?.userAvatar,
                alt: "avatar"
              }, null, 8, _hoisted_5),
              _createVNode(_unref(IconUser))
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(store).state.user?.loginUser?.userName), 1),
          _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("span", _hoisted_8, [
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text-dark-emphasis" }, "账户: ", -1)),
            _createTextVNode(_toDisplayString(_unref(store).state.user?.loginUser?.userAccount), 1)
          ]),
          _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("p", _hoisted_9, _toDisplayString(_unref(store).state.user?.loginUser.userProfile ?? "这个逼很赖，没有写简介"), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "details-title" }, " AC数量： ", -1)),
          _createElementVNode("span", _hoisted_12, _toDisplayString(acCount.value), 1)
        ]),
        _createElementVNode("div", _hoisted_13, [
          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "details-title" }, " 发帖数量： ", -1)),
          _createElementVNode("span", _hoisted_14, _toDisplayString(_unref(total)), 1)
        ]),
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "col-2" }, null, -1)),
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "col-2" }, null, -1)),
        _createElementVNode("div", { class: "col-4 d-flex justify-content-end" }, [
          _createElementVNode("button", {
            class: "btn btn-outline-primary mx-3",
            onClick: toUpdate
          }, "修改资料"),
          _createElementVNode("button", {
            class: "btn btn-primary",
            onClick: toAddPost
          }, "发布帖子")
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchParams.value.searchText) = $event)),
              "aria-describedby": "search",
              "aria-label": "Recipient's username",
              class: "form-control orm-control-lg border-2 border-primary-subtle rounded-0",
              placeholder: "输入文本以查询",
              type: "text"
            }, null, 512), [
              [_vModelText, searchParams.value.searchText]
            ]),
            _createElementVNode("button", {
              id: "search",
              class: "btn btn-primary",
              style: {"width":"15%"},
              type: "button",
              onClick: doSearch
            }, "搜索")
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _createVNode(_component_a_table, {
                    ref: tableRef.value,
                    bordered: true,
                    columns: columns,
                    data: myPosts.value,
                    loading: isLoading.value,
                    pagination: {
                         pageSize: searchParams.value.pageSize,
                         current: searchParams.value.current,
                          total: _unref(total),
                      },
                    class: "pointer",
                    onRowClick: toPostPage,
                    onPageChange: onPageChange
                  }, {
                    title: _withCtx(({record}) => [
                      _createElementVNode("span", {
                        class: "title",
                        onClick: ($event: any) => (toPostPage(record))
                      }, _toDisplayString(record.title), 9, _hoisted_23)
                    ]),
                    createTime: _withCtx(({ record }) => [
                      _createTextVNode(_toDisplayString(_unref(formatDate)(new Date(record.createTime))), 1)
                    ]),
                    updateTime: _withCtx(({ record }) => [
                      _createTextVNode(_toDisplayString(_unref(formatDate)(new Date(record.updateTime))), 1)
                    ]),
                    tagList: _withCtx(({record}) => [
                      (record.tagList.length === 0)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_24, " 啥也木有！ "))
                        : _createCommentVNode("", true),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.tagList, (tag) => {
                        return (_openBlock(), _createElementBlock("span", {
                          key: tag.id,
                          class: "badge text-bg-secondary mx-1",
                          style: {"font-weight":"500"}
                        }, _toDisplayString(tag), 1))
                      }), 128))
                    ]),
                    optional: _withCtx(({ record }) => [
                      _createVNode(_component_a_space, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_button, {
                            type: "primary",
                            onClick: ($event: any) => (doUpdate({ ...record}))
                          }, {
                            default: _withCtx(() => _cache[8] || (_cache[8] = [
                              _createTextVNode("修改")
                            ])),
                            _: 2
                          }, 1032, ["onClick"]),
                          _createVNode(_component_a_button, {
                            status: "danger",
                            onClick: ($event: any) => (doDelete({ ...record }))
                          }, {
                            default: _withCtx(() => _cache[9] || (_cache[9] = [
                              _createTextVNode("删除")
                            ])),
                            _: 2
                          }, 1032, ["onClick"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 1
                  }, 8, ["data", "loading", "pagination"]),
                  (myPosts.value.length < 8)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                        _createVNode(_component_a_empty, { description: "没有更多了" })
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "questionsView" }
const _hoisted_2 = ["onClick"]

import {onMounted, ref, watchEffect} from 'vue';
  import {Question, QuestionControllerService} from "../../../generated";
  import message from "@arco-design/web-vue/es/message";
  import {useRouter} from "vue-router";


  
export default /*@__PURE__*/_defineComponent({
  __name: 'QuestionsView',
  setup(__props) {

  const total = ref(0);
  const datalist = ref([]);
  const searchParams = ref({
    title: "",
    tags: [],
    pageSize: 20,
    current: 1,
  });

  const loadData = async () => {
    const res = await QuestionControllerService.listQuestionVoByPageUsingPost(searchParams.value);
    if (res.code === 0) {
      datalist.value = res.data.records;
      total.value = res.data.total;
    } else {
      message.error("加载失败," + res.message);
    }
  }
  // 初始化时请求数据
  onMounted(() => {
    loadData();
  });

  const columns = [
    {
      title: "题目名称",
      slotName: "title",
    },
    {
      title: "标签",
      slotName: "tags",
    },
    {
      title: "通过率",
      slotName: "acceptedRate",
    },
    {
      title: "创建时间",
      slotName: "createTime",
    },
    // {
    //   slotName: "optional",
    // },
  ];

  const router = useRouter();

  const onPageChange = (page: number) => {
    searchParams.value = {
      ...searchParams.value,
      current: page
    }
  }

  // 监听数据变化
  watchEffect(() => {
    loadData();
  });

  const getTagColor = (tag: string) => {
    switch (tag) {
      case '简单':
        return 'green';
      case '中等':
        return 'orangered';
      case '困难':
        return 'red';
      default:
        return 'magenta';
    }
  }

  // 创建时间改为YYYY-MM-DD格式
  const formatDate = (date: string) => {
    const d = new Date(date);
    return d.toLocaleDateString();
  }
  /**
   * 跳转到做题页面
   * @param question
   */
  const toQuestionPage = (question: Question) => {
    // router.push({
    //   path: `/view/question/${question.id}`,
    // });
    window.open(`/view/question/${question.id}`, "_blank");
  };

  /**
   * 确认搜索，重新加载数据
   */
  const doSubmit = () => {
    // 这里需要重置搜索页号
    searchParams.value = {
      ...searchParams.value,
      current: 1,
    };
  };


return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_tag = _resolveComponent("a-input-tag")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_form, {
      model: searchParams.value,
      class: "w-100 justify-content-center",
      layout: "inline"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, {
          field: "title",
          label: "名称",
          style: {"min-width":"240px","width":"40%"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              modelValue: searchParams.value.title,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchParams.value.title) = $event)),
              placeholder: "请输入名称"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "tags",
          label: "标签",
          style: {"min-width":"240px","width":"40%"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input_tag, {
              modelValue: searchParams.value.tags,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((searchParams.value.tags) = $event)),
              placeholder: "请输入标签"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { style: {"width":"12%"} }, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              class: "w-100",
              type: "primary",
              onClick: doSubmit
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("提交")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"]),
    _createVNode(_component_a_divider, { size: "0" }),
    _createVNode(_component_a_table, {
      columns: columns,
      data: datalist.value,
      pagination: {pageSize: searchParams.value.pageSize,
        current: searchParams.value.current,
        total: total.value,
        showTotal: false
        },
      onPageChange: onPageChange
    }, {
      title: _withCtx(({record}) => [
        _createElementVNode("span", {
          class: "title",
          onClick: ($event: any) => (toQuestionPage(record))
        }, _toDisplayString(record.title), 9, _hoisted_2)
      ]),
      tags: _withCtx(({record}) => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.tags, (tag) => {
          return (_openBlock(), _createBlock(_component_a_tag, {
            key: tag.id,
            color: getTagColor(tag),
            style: {"margin-right":"1vh"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(tag), 1)
            ]),
            _: 2
          }, 1032, ["color"]))
        }), 128))
      ]),
      acceptedRate: _withCtx(({ record }) => [
        _createTextVNode(_toDisplayString(`${
              record.submitNum
                  ? `${((record.acceptedNum / record.submitNum) * 100).toFixed(2)}% (${record.acceptedNum}/${record.submitNum})`
                  : "0.00% (0/0)"
          }`), 1)
      ]),
      createTime: _withCtx(({ record }) => [
        _createTextVNode(_toDisplayString(formatDate(record.createTime)), 1)
      ]),
      _: 1
    }, 8, ["data", "pagination"])
  ]))
}
}

})
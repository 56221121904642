import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "center" }
const _hoisted_2 = { id: "addQuestion" }
const _hoisted_3 = { style: {"display":"flex","margin-top":"12px","margin-bottom":"30px","align-items":"center","justify-content":"center"} }
const _hoisted_4 = { style: {"margin-top":"33px","display":"flex","align-items":"center","justify-content":"center"} }

import {onMounted, ref} from 'vue';
  import MDEditor from "@/components/MDEditor.vue";
  import {Message} from "@arco-design/web-vue";
  import {QuestionControllerService} from "../../../generated";
  import store from "@/store";
  import checkAccess from "@/access/checkAccess";
  import ACCESS_ENUM from "@/access/accessEnum";
  import {useRouter} from "vue-router";

  const contentText =
      "首先你是一只猫，本题目要求读入$2$个整数$A$和$B$，然后输出它们的和。\n\n"
      + "如图所示，这是一只猫猫。(图片可以直接复制黏贴)\n"
      + "![image.png](http://image.niwenjia.cn/bcb2ceeb70a74c04a1313a5191d039d9.png \"image.png\")\n\n"
      + "### 输入格式:"
      + "\n请在这里写输入格式。例如：输入在一行中给出$2$个绝对值不超过$10$的整数$A$和$B$。\n"
      + "```in\n 1 2\n ```\n"
      + " \n### 输出格式:\n" + "在这里给出相应的输出。例如：\n"
      + "```out\n miao miao miao\n```\n"

  const ansText =
      "# 题意\n> 首先猫猫很聪明\n> 其次猫猫不会讲人话\n\n" + "" +
      "# 思路\n字符串模拟猫猫讲话\n"
      + "# Code\n"
      + "```cpp"
      + "\n＃include 《i0stream》\n"
      + "long long mian()\n"
      + "{\n    int A，B;\n"
      + "    cin>>A>>B；\n\n"
      + "    for{int i = 1; i <= (a+b); i加加}\n"
      + "    (\n"
      + "        cout👈👈“mia”;\n"
      + "        if(i != (a+b) ) cout ←← ' ';\n"
      + "    )\n"
      + "\n    return O;\n"
      + "}\n"
      + "```"

  
export default /*@__PURE__*/_defineComponent({
  __name: 'AddQuestionView',
  setup(__props) {

  const form = ref({
    title: "",
    content: "",
    tags: ["简单"],
    answer: "",
    submitNum: 0,
    acceptedNum: 0,
    judgeConfig: {
      memoryLimit: 262144,
      stackLimit: 8192,
      timeLimit: 800
    },
    judgeCase: [
      {
        input: "",
        output: ""
      },
    ],
  });

  const handleAdd = () => {
    if (form.value) {
      form.value.judgeCase.push({
        input: '',
        output: ''
      })
    }
  };
  const handleDelete = (index: number) => {
    if (form.value) {
      form.value.judgeCase.splice(index, 1)
    }
  }
  const doSubmit = async () => {
    if (form.value.title === '') {
      Message.error("标题不能为空")
      return
    }
    if (form.value.content === '') {
      // console.log("form.value", form.value.content)
      Message.error("内容不能为空")
      return
    }
    if (form.value.tags.length === 0) {
      Message.error("标签里面至少要有个难度吧~")
      return
    }
    if (form.value.answer === '') {
      Message.error("题目解析不能为空")
      return
    }
    if (!form.value.judgeConfig.timeLimit) {
      Message.error("时间限制不能为空")
      return
    } else if (form.value.judgeConfig.timeLimit < 400) {
      Message.error("时间限制不能小于400ms")
      return
    }
    if (!form.value.judgeConfig.memoryLimit) {
      Message.error("空间限制不能为空")
      return
    } else if (form.value.judgeConfig.memoryLimit < 32768) {
      Message.error("空间限制不能小于32Mb")
      return
    }
    if (!form.value.judgeConfig.stackLimit) {
      Message.error("堆栈限制不能为空")
      return
    } else if (form.value.judgeConfig.stackLimit < 8192) {
      Message.error("堆栈限制不能小于8Mb")
      return
    }
    // form.value.tags.push(store.state.user?.loginUser?.userName ?? '');
    const res = await QuestionControllerService.addQuestionUsingPost(form.value);
    if (res.code == 0) {
      Message.success("添加成功")
      // 跳转
      setTimeout(() => {
        window.location.href = "/view/question/"+res.data;
      }, 200)
    } else {
      Message.error("添加失败" + res.message)
    }
  }

  const onContentChange = (value: string) => {
    form.value.content = value;
    // console.log("debug", value)
    // alert(1)
  };

  const onAnswerChange = (answer: string) => {
    form.value.answer = answer
  };

  // const rules = {
  //   timeLimit: [
  //     {
  //       required: true,
  //       message: 'timeLimit is required',
  //     },
  //   ],
  //   memoryLimit: [
  //     {
  //       required: true,
  //       message: 'memoryLimit is required',
  //     },
  //   ],
  //   stackLimit: [
  //     {
  //       required: true,
  //       message: 'stackLimit is required',
  //     }
  //   ]
  // }
  const insert = () => {
    form.value.content = contentText;
    form.value.answer = ansText;
  }
  const router = useRouter();
  onMounted( ()=>{
    const loginUser = store.state.user.loginUser;
    if(!checkAccess(loginUser, ACCESS_ENUM.ADMIN)){
      router.push({
        path: '/noAuth',
        replace: true
      });
    }
  })

  setTimeout(() => {
    const mdList = document.getElementsByClassName("bytemd bytemd-split");
    if (mdList[0] && mdList[1]) {
      (mdList[0] as HTMLElement).addEventListener("click", () => {
        (mdList[0] as HTMLElement).style.zIndex = '2';
        // Message.success("z-index修改")
      });
      (mdList[1] as HTMLElement).addEventListener("click", () => {
        (mdList[1] as HTMLElement).style.zIndex = '2';
        // Message.success("z-index修改")
      });
    }
  }, 0);


return (_ctx: any,_cache: any) => {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_tag = _resolveComponent("a-input-tag")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[9] || (_cache[9] = _createElementVNode("h1", { style: {"text-align":"center"} }, "创建题目", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_button, {
        size: "large",
        status: "success",
        type: "dashed",
        onClick: insert
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode("模板插入")
        ])),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_form, {
        model: form.value,
        "auto-label-width": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            field: "title",
            label: "标题"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                modelValue: form.value.title,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.title) = $event)),
                placeholder: "输入标题"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            field: "content",
            label: "题目内容"
          }, {
            default: _withCtx(() => [
              _createVNode(MDEditor, {
                id: "md1",
                "handle-change": onContentChange,
                value: form.value.content,
                style: {"width":"100%"}
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            field: "answer",
            label: "官方题解"
          }, {
            default: _withCtx(() => [
              _createVNode(MDEditor, {
                id: "md2",
                "handle-change": onAnswerChange,
                value: form.value.answer,
                style: {"width":"100%"}
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            field: "tags",
            label: "标签"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input_tag, {
                modelValue: form.value.tags,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.tags) = $event)),
                "allow-clear": "",
                placeholder: "选择标签"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            "content-flex": false,
            "merge-props": false,
            label: "案例"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(form.value.judgeCase, (judgeCaseItem, index) => {
                return (_openBlock(), _createBlock(_component_a_form_item, {
                  key: index,
                  label: `案例 ${index}`,
                  field: "judgeCase",
                  "no-style": ""
                }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_component_a_form_item, {
                      key: index,
                      field: `form.judgeCase[${index}].input`,
                      label: `输入案例 ${index}`
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_textarea, {
                          modelValue: judgeCaseItem.input,
                          "onUpdate:modelValue": ($event: any) => ((judgeCaseItem.input) = $event)
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 2
                    }, 1032, ["field", "label"])),
                    (_openBlock(), _createBlock(_component_a_form_item, {
                      key: index,
                      field: `form.judgeCase[${index}].output`,
                      label: `输出案例 ${index}`
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_textarea, {
                          modelValue: judgeCaseItem.output,
                          "onUpdate:modelValue": ($event: any) => ((judgeCaseItem.output) = $event)
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ]),
                      _: 2
                    }, 1032, ["field", "label"])),
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_a_button, {
                        status: "danger",
                        onClick: ($event: any) => (handleDelete(index))
                      }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode("Delete")
                        ])),
                        _: 2
                      }, 1032, ["onClick"])
                    ])
                  ]),
                  _: 2
                }, 1032, ["label"]))
              }), 128)),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_a_button, {
                  status: "success",
                  type: "outline",
                  onClick: handleAdd
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode("新增测试用例")
                  ])),
                  _: 1
                })
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, {
            "content-flex": false,
            "merge-props": false,
            label: "题目限制"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_space, { direction: "vertical" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    field: "timeLimit",
                    label: "时间限制(ms)",
                    "validate-trigger": "input"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input_number, {
                        modelValue: form.value.judgeConfig.timeLimit,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.judgeConfig.timeLimit) = $event)),
                        min: "400",
                        mode: "button",
                        placeholder: "输入时间限制(ms)...",
                        size: "large"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_form_item, {
                    field: "memoryLimit",
                    label: "空间限制(kb)",
                    "validate-trigger": "input"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input_number, {
                        modelValue: form.value.judgeConfig.memoryLimit,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.judgeConfig.memoryLimit) = $event)),
                        min: "32768",
                        mode: "button",
                        placeholder: "输入空间限制(ms)...",
                        size: "large"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_form_item, {
                    field: "stackLimit",
                    label: "堆栈限制(kb)",
                    "validate-trigger": "input"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_input_number, {
                        modelValue: form.value.judgeConfig.stackLimit,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((form.value.judgeConfig.stackLimit) = $event)),
                        min: "8192",
                        mode: "button",
                        placeholder: "输入栈限制(kb)...",
                        size: "large"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { style: {"display":"flex","width":"100%","align-items":"center","justify-content":"center !important"} }, {
            default: _withCtx(() => [
              _createVNode(_component_a_button, {
                "html-type": "submit",
                type: "primary",
                onClick: doSubmit
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode("Submit")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model"])
    ])
  ], 64))
}
}

})
<template>
  <div id="MineView">
    <!--介绍板块-->
    <div id="banner" class="container text-center p-4 shadow" style="min-width: 80%;">
      <div class="row align-items-center ">
        <div class="col-auto d-flex">
          <a-avatar :size=120 :style="{ backgroundColor: '#14C9C9' }" class="bg-primary-subtle shadow" shape="square"
                    @click="upLoadNewAvatar">
            <img
                :src="store.state.user?.loginUser?.userAvatar"
                alt="avatar"
            />
            <IconUser/>
            <template #trigger-icon>
              <IconEdit :size=15 />
            </template>
          </a-avatar>
        </div>
        <div class="col-auto align-middle text-center">
          <span class="name">
            {{ store.state.user?.loginUser?.userName }}
          </span>
          <br>
          <span class="intro">
            <span class="text-dark-emphasis">账户: </span>{{ store.state.user?.loginUser?.userAccount }}
          </span>
          <br>
          <p class="intro">{{ store.state.user?.loginUser.userProfile ?? "这个逼很赖，没有写简介" }}</p>
        </div>
      </div>
      <!--一行四个-->
      <div class="row mt-2">
        <div class="col-2">
          <span class="details-title">
            AC数量：
          </span>
          <span class="details-content">
            {{ acCount }}
          </span>
        </div>
        <div class="col-2">
          <span class="details-title">
            发帖数量：
          </span>
          <span class="details-content">
            {{ total }}
          </span>
        </div>
        <div class="col-2"></div>
        <div class="col-2"></div>
        <div class="col-4 d-flex justify-content-end">
          <button class="btn btn-outline-primary mx-3" @click="toUpdate">修改资料</button>
          <button class="btn btn-primary" @click="toAddPost">发布帖子</button>
        </div>
      </div>
    </div>
    <!--历史记录板块-->
    <div class="w-100 container text-center p-2">
      <div class="row">
        <div class="col-12">
          <div class="input-group my-3 w-100 mx-auto">
            <input v-model="searchParams.searchText" aria-describedby="search"
                   aria-label="Recipient's username"
                   class="form-control orm-control-lg border-2 border-primary-subtle rounded-0"
                   placeholder="输入文本以查询"
                   type="text">
            <button id="search" class="btn btn-primary" style="width: 15%" type="button" @click="doSearch">搜索</button>
          </div>
          <div class=" d-flex container justify-content-center">
            <div class="my-3 w-75 text-center">
              <div class="card">
                <div class="container p-2">
                  <a-table
                      :ref="tableRef"
                      :bordered=true
                      :columns="columns"
                      :data="myPosts"
                      :loading="isLoading"
                      :pagination="{
                         pageSize: searchParams.pageSize,
                         current: searchParams.current,
                          total,
                      }"
                      class="pointer"
                      @row-click="toPostPage"
                      @page-change="onPageChange"
                  >
                    <template #title="{record}">
                      <span class="title" @click="toPostPage(record)">{{ record.title }}</span>
                    </template>
                    <template #createTime="{ record }">
                      {{ formatDate(new Date(record.createTime)) }}
                    </template>
                    <template #updateTime="{ record }">
                      {{ formatDate(new Date(record.updateTime)) }}
                    </template>
                    <template #tagList="{record}">
                      <span v-if="record.tagList.length === 0"> 啥也木有！ </span>
                      <span v-for="tag in record.tagList" :key="tag.id" class="badge text-bg-secondary mx-1"
                            style="font-weight: 500;">{{ tag }}</span>
                    </template>
                    <template #optional="{ record }">
                      <a-space>
                        <a-button type="primary" @click="doUpdate({ ...record})">修改</a-button>
                        <a-button status="danger" @click="doDelete({ ...record })">删除</a-button>
                      </a-space>
                    </template>
                  </a-table>
                  <div v-if="myPosts.length < 8">
                    <a-empty description="没有更多了"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--todo?-->
        <!--<div class="col-2">-->
        <!--  <ul class="list-group w-100">-->
        <!--    <li aria-current="true" class="list-group-item active">An active item</li>-->
        <!--    <li class="list-group-item" style="height: 1000px">And a fifth one</li>-->
        <!--  </ul>-->
        <!--</div>-->
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import store from "@/store";
  import {
    PostControllerService,
    PostQueryRequest,
    PostVO,
    Question,
    QuestionControllerService
  } from "../../../generated";
  import {onMounted, ref} from "vue";
  import message from "@arco-design/web-vue/es/message";
  import formatDate from "../../access/Date";
  import {useRouter} from "vue-router";
  import {IconEdit, IconUser} from '@arco-design/web-vue/es/icon';

  const myPosts = ref<PostVO[]>([]);
  const acCount = ref(0);
  const tableRef = ref();
  const uid = store.state.user.loginUser.id;
  let total = ref(0);
  const router = useRouter();
  const columns = [
    {
      title: "标题",
      slotName: "title",
      ellipsis: true,
    },
    {
      title: "创建时间",
      slotName: "createTime",
    },
    {
      title: '上次修改时间',
      slotName: 'updateTime',
    },
    {
      title: '标签',
      slotName: "tagList",
      ellipsis: true,
    },
    {
      title: '操作',
      slotName: 'optional'
    },
  ];
  const searchParams = ref<PostQueryRequest>({
    searchText: "",
    tags: [],
    pageSize: 8,
    current: 1,
    userId: uid,
  });
  const isLoading = ref(false);
  const doSearch = async () => {
    searchParams.value = {
      ...searchParams.value,
      current: 1
    }
    await loadData();
    if (myPosts.value?.length == 0) {
      // message.info('没有找到');
    }
  };
  const loadData = async () => {
    isLoading.value = true;
    const acCountRes = await QuestionControllerService.getAcCountByUserIdUsingGet(uid);
    if (acCountRes.code === 0) {
      acCount.value = acCountRes.data ?? 0;
    } else {
      message.error("加载失败，" + acCountRes.message);
    }
    const res = await PostControllerService.listMyPostVoByPageUsingPost(searchParams.value);
    if (res.code === 0) {
      myPosts.value = res.data.records;
      total.value = res.data.total;
    } else {
      message.error("加载失败，" + res.message);
    }
    isLoading.value = false;
  };

  const onPageChange = async (page: number) => {
    // message.info("page="+page)
    searchParams.value = {
      ...searchParams.value,
      current: page
    }
    await loadData();
  }

  const toPostPage = (post: PostVO) => {
    router.push({
      path: `/view/post/${post.id}`,
    });
  }

  onMounted(async () => {
    await loadData();
    // 监听回车
    // fixme: 应该只监听当前窗口 
    window.addEventListener('keyup', (event) => {
      // Message.info("keyup: " + event.key);
      if (event.key === 'Enter') {
        doSearch();
      }
    });
  });

  const doDelete = async (post: PostVO) => {
    const res = await PostControllerService.deletePostUsingPost({id: post.id});
    if (res.code === 0) {
      message.success("删除成功");
      await loadData();
      await router.push({
        path: "/mine",
      });
    } else {
      message.error("删除失败", res.message);
    }
  }

  const doUpdate = (question: Question) => {
    router.push({
      path: "/update/post",
      query: {
        id: question.id
      }
    })
  }

  const toUpdate = () => {
    router.push({
      path: "/update/mine",
    })
  }

  const upLoadNewAvatar = () => {
    router.push({
      path: "/update/mine",
    })
  }

  // 跳转到发布帖子页面
  const toAddPost = () => {
    router.push('/add/post');
  }
</script>

<style scoped>
  #banner {
    background: #fcfcfc;
    width: 100%
  }

  #MineView {
    height: 100vh;
    width: 100%;
    padding: 15px;
    background: linear-gradient(to bottom, #409df8, #fff);
  }

  .name {
    font-size: 3ch;
    color: #000000;
    font-weight: 600;
    font-family: "Microsoft YaHei", serif;
  }

  .intro {
    font-family: "Microsoft YaHei", serif;
    font-size: 14px;
    color: #6b7280;
  }

  .details-title {
    font-family: "Microsoft YaHei", serif;
    font-size: 1.5ch;
    color: #6b7280;
  }

  .details-content {
    font-family: "Microsoft YaHei", serif;
    font-size: 2ch;
    color: #000000;
  }

  .pointer {
    cursor: pointer;
  }
</style>
import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {onMounted, ref, watchEffect} from 'vue';
  import {Question, QuestionControllerService} from "../../../generated";
  import message from "@arco-design/web-vue/es/message";
  import {useRouter} from "vue-router";
  import store from "@/store";
  import accessEnum from "@/access/accessEnum";

  
export default /*@__PURE__*/_defineComponent({
  __name: 'ManageQuestionView',
  setup(__props) {

  const total = ref(0);
  const datalist = ref([]);
  const searchParams = ref({
    pageSize: 10,
    current: 1,
    title: "",
    tags: [],
  });
  const loadData = async () => {
    let res;
    if (store.state.user.loginUser.userRole === accessEnum.ADMIN) {
      res = await QuestionControllerService.listQuestionByPageUsingPost(searchParams.value);
    } else {
      res = await QuestionControllerService.listMyQuestionVoByPageUsingPost(searchParams.value);
    }
    // console.log("题目管理", res.data)
    if (res.code === 0) {
      datalist.value = res.data.records;
      total.value = res.data.total;
    } else {
      message.error("加载失败," + res.message);
    }
  }
  // 初始化时请求数据
  onMounted(() => {
    loadData();
  });

  const columns =
      [
        {
          title: '标题',
          dataIndex: 'title',
          ellipsis: true,
        },
        {
          title: '内容',
          dataIndex: 'content',
          ellipsis: true,
        },
        {
          title: '标签',
          dataIndex: 'tags',
          ellipsis: true,
        },
        {
          title: '答案',
          dataIndex: 'answer',
          ellipsis: true,
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          ellipsis: true,
        },
        {
          title: '修改时间',
          dataIndex: 'updateTime',
          ellipsis: true,
        },
        {
          title: '操作',
          slotName: 'optional'
        },
      ];

  const doDelete = async (question: Question) => {
    const res = await QuestionControllerService.deleteQuestionUsingPost({id: question.id});
    if (res.code === 0) {
      message.success("删除成功");
      loadData();
    } else {
      message.error("删除失败", res.message);
    }
  }

  const router = useRouter();

  const doUpdate = (question: Question) => {
    router.push({
      path: "/update/question",
      query: {
        id: question.id
      }
    })
  }

  const onPageChange = (page: number) => {
    searchParams.value = {
      ...searchParams.value,
      current: page
    }
  }

  // 监听数据变化
  watchEffect(() => {
    loadData();
  });

  const doSubmit = () => {
    // 这里需要重置搜索页号
    searchParams.value = {
      ...searchParams.value,
      current: 1,
    };
  };


return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_tag = _resolveComponent("a-input-tag")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_form, {
      model: searchParams.value,
      class: "w-100 justify-content-center",
      layout: "inline"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, {
          field: "title",
          label: "名称",
          style: {"min-width":"240px","width":"40%"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              modelValue: searchParams.value.title,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchParams.value.title) = $event)),
              placeholder: "请输入名称"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "tags",
          label: "标签",
          style: {"min-width":"240px","width":"40%"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input_tag, {
              modelValue: searchParams.value.tags,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((searchParams.value.tags) = $event)),
              placeholder: "请输入标签"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { style: {"width":"12%"} }, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: doSubmit,
              class: "w-100"
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("提交")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"]),
    _createVNode(_component_a_table, {
      columns: columns,
      data: datalist.value,
      pagination: {pageSize: searchParams.value.pageSize,
        current: searchParams.value.current,
        total: total.value,
        showTotal: true
        },
      onPageChange: onPageChange
    }, {
      optional: _withCtx(({ record }) => [
        _createVNode(_component_a_space, null, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: ($event: any) => (doUpdate({ ...record}))
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("修改")
              ])),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_a_button, {
              status: "danger",
              onClick: ($event: any) => (doDelete({ ...record }))
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode("删除")
              ])),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["data", "pagination"])
  ]))
}
}

})
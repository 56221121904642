<template>
  <a-config-provider>
    <div id="app">
      <template v-if="route.path.startsWith('/user')">
        <router-view
            style="background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%); display: flex !important;margin: auto 0;"/>
      </template>
      <template v-else-if="route.path.startsWith('/view/question/')">
        <BasicLayoutWithoutFooter/>
      </template>
      <template v-else-if="route.path.startsWith('/mine') || route.path.startsWith('/view/other')">
        <BasicLayoutWithoutMB/>
      </template>
      <template v-else-if="route.path.startsWith('/view/post')">
        <BasicLayoutWithoutMB/>
      </template>
      <template v-else-if="route.path.startsWith('/welcome')">
        <router-view style="background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);"/>
      </template>
      <template v-else>
        <BasicLayout/>
      </template>
    </div>
  </a-config-provider>
</template>

<style>
  #app {
  }

  * {
    font-family: "Microsoft YaHei", serif;
  }

  .arco-avatar{
   background: none !important;
  }
</style>
<script lang="ts" setup>
  import {onMounted} from "vue";
  import {useRoute} from "vue-router";
  import BasicLayoutWithoutFooter from "@/layouts/BasicLayoutWithoutFooter.vue";
  import BasicLayoutWithoutMB from "@/layouts/BasicLayoutWithoutMB.vue";
  import dayjs from 'dayjs';
  import 'dayjs/locale/zh-cn';
  import BasicLayout from "@/layouts/BasicLayout.vue";

  dayjs.locale('zh-cn');

  const route = useRoute();

  /**
   * todo
   * 全局初始化函数，全局单次调用的代码写
   * 佛祖保佑
   */
  const doInit = () => {

    /*----------------------------以下为核心代码，观看时请保持敬畏之心----------------------------*/
    console.log(
        '/**\n' +
        ' *                             _ooOoo_\n' +
        ' *                            o8888888o\n' +
        ' *                            88" . "88\n' +
        ' *                            (| -_- |)\n' +
        ' *                            O\\  =  /O\n' +
        ' *                         ____/`---\'\\____\n' +
        ' *                       .\'  \\\\|     |//  `.\n' +
        ' *                      /  \\\\|||  :  |||//  \\\n' +
        ' *                     /  _||||| -:- |||||-  \\\n' +
        ' *                     |   | \\\\\\  -  /// |   |\n' +
        ' *                     | \\_|  \'\'\\---/\'\'  |   |\n' +
        ' *                     \\  .-\\__  `-`  ___/-. /\n' +
        ' *                   ___`. .\'  /--.--\\  `. . __\n' +
        ' *                ."" \'<  `.___\\_<|>_/___.\'  >\'"".\n' +
        ' *               | | :  `- \\`.;`\\ _ /`;.`/ - ` : | |\n' +
        ' *               \\  \\ `-.   \\_ __\\ /__ _/   .-` /  /\n' +
        ' *          ======`-.____`-.___\\_____/___.-`____.-\'======\n' +
        ' *                             `=---=\'\n' +
        ' *          ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^\n' +
        ' *                     佛祖保佑        永无BUG\n' +
        ' *            佛曰:\n' +
        ' *                   写字楼里写字间，写字间里程序员；\n' +
        ' *                   程序人员写程序，又拿程序换酒钱。\n' +
        ' *                   酒醒只在网上坐，酒醉还来网下眠；\n' +
        ' *                   酒醉酒醒日复日，网上网下年复年。\n' +
        ' *                   但愿老死电脑间，不愿鞠躬老板前；\n' +
        ' *                   奔驰宝马贵者趣，公交自行程序员。\n' +
        ' *                   别人笑我忒疯癫，我笑自己命太贱；\n' +
        ' *                   不见满街漂亮妹，哪个归得程序员？\n' +
        '*/\n');
    /*----------------------------以上为核心代码，观看时请保持敬畏之心----------------------------*/
  };

  onMounted(() => {
    doInit();
  });
</script>

<template>
  <div class="my-0 p-2" style="background: #f0f2f5; min-height: 90vh">
    <div class="container p-0">
      <div v-if="post && post.user && post.createTime" class="row m-0">
        <div class="col-12 col-xl-8 m-0 p-0">
          <div class="card">
            <div class="card-header">
              <h1 class="m-2" style="margin-bottom: 0;">{{ post.title }}</h1>
              <div class="m-2 text-body-secondary">
                作者：{{ post.user.userName }} | 发布日期：{{ formatDate(new Date(post.createTime)) }}
              </div>
            </div>
            <div class="card-body p-3">
              <MDViewerPost :value=" post.content || ''" style="width: 100%;"/>
            </div>
            <div class="card-footer bg-light-subtle p-0 m-0">
          <span v-for="item in post.tagList" :key="item" class="mx-2"><a-tag class="rounded-1"
                                                                             style="margin-top: 1rem; margin-bottom: 1rem;">
            {{ item }}</a-tag></span>
              <div class="w-100 rounded-0 list-group text-center list-group-horizontal p-0 row m-0">
            <span class="rounded-0 list-item-container list-group-item col-3" @click="onLikeChange(post)">
                  <span key="heart" class="action">
                    <span v-if="post.hasThumb"><IconHeartFill :style="{ color: '#f53f3f' }"/></span>
                    <span v-else><IconHeart/></span>{{ post.thumbNum }}
                  </span>
            </span>
                <span class="rounded-0 list-item-container list-group-item col-3" @click="onStarChange(post)">
              <span key="star" class="action">

                <span v-if="post.hasFavour"><IconStarFill :style="{ color: '#ffb400' }"/></span>
                <span v-else> <IconStar/></span>
                {{ post.favourNum }}
              </span>
            </span>
                <span class="rounded-0 list-item-container list-group-item col-3" @click="doShare(post)">
              <IconShareAlt/> 分享
            </span>
                <a-popover class="" trigger="click">
                    <span class="rounded-0 list-item-container list-group-item col-3">
                           <IconUnorderedList/> 操作
                    </span>
                  <template #content>
                    <div class="list-group p-0">
                      <p class="list-group-item-action list-group-item-light pointer"
                         @click="()=>{message.warning('这个功能懒得写了，建议直接联系管理员~')}">
                        &nbsp;
                        <IconExclamationCircle/>
                        举报
                        &nbsp;&nbsp;
                      </p>
                      <!--需要权限，本人或管理员-->
                      <div
                          v-if="checkAccess(store.state.user.loginUser,ACCESS_ENUM.ADMIN) || post.user.id === store?.state.user.loginUser.id">
                        <p class="list-group-item-action list-group-item-light pointer" @click="doUpdate(post)">
                          &nbsp;
                          <IconSettings/>
                          修改
                          &nbsp;&nbsp;
                        </p>
                        <p class="list-group-item-action list-group-item-light pointer" @click="doDelete(post)">
                          &nbsp;
                          <IconDelete/>
                          删除
                          &nbsp;&nbsp;
                        </p>
                      </div>
                    </div>
                  </template>
                </a-popover>
              </div>
            </div>
          </div>
          <div class="card my-2" style="background: #ffffff;">
            <div class="card-header" style="background: none">
              <h4>评论</h4>
            </div>
            <div class="d-flex my-2 p-3">
              <a-avatar :size=60 shape="square" style="margin-right: 1rem;">
                <img
                    :src="store.state.user?.loginUser?.userAvatar"
                    alt="avatar"
                    style="height: 60px; width: 60px;max-width: 150px !important;"
                />
              </a-avatar>
              <div class="card w-100 shadow border-0 p-3">
                <a-textarea
                    v-model="postReply.content"
                    :auto-size="{
                      minRows: 3,
                      maxRows: 7
                    }"
                    :max-length="666"
                    :placeholder="'来和' + post.user?.userName + '同学说点什么吧~'"
                    class="w-100 rounded-2"
                    show-word-limit
                >
                </a-textarea>
                <div class="d-flex justify-content-between" style="margin-top: 1rem;">
                  <div class="d-flex">
                    <a-button icon="smile" style="margin-right: 1rem;" type="text" @click="doClear">清空</a-button>
                  </div>
                  <button class="btn btn-primary" style="width: 16%" type="button" @click="doReply">发布</button>
                </div>
              </div>
            </div>
            <a-empty v-if="!postReplyList ||postReplyList.length === 0" description="暂无评论"/>
            <div v-for="(item, index) in postReplyList" :key="index" class="p-2">
              <div class="bg-secondary-subtle w-100" style="height: 1px;"></div>
              <a-comment :author="item?.user.userName" :datetime="formatDate(new Date(item.updateTime as string))"
                         align="right">
                <template #avatar>
                  <a-avatar :size=45 shape="square" @click="toOtherUser(item?.user.id as number)">
                    <img
                        :src="item?.user.userAvatar"
                        alt="avatar"
                    />
                  </a-avatar>
                </template>
                <template #content>
                  <div>
                    <MDViewerForAI :value="item.content"/>
                  </div>
                </template>
                <template #actions>
                  <a-popover class="" trigger="click">
                      <span class="rounded-0 list-item-container">
                       <IconUnorderedList/> 操作
                          </span>
                    <template #content>
                      <div class="list-group p-0">
                        <p class="list-group-item-action list-group-item-light pointer"
                           @click="()=>{message.warning('这个功能懒得写了，建议直接联系管理员~')}">
                          &nbsp;
                          <IconExclamationCircle/>
                          举报
                          &nbsp;&nbsp;
                        </p>
                        <!--需要权限，本人或管理员-->
                        <div
                            v-if="checkAccess(store.state.user.loginUser,ACCESS_ENUM.ADMIN) || item.userId === store?.state.user.loginUser.id">
                          <p class="list-group-item-action list-group-item-light pointer"
                             @click="doDeleteReply(item.id as any)">
                            &nbsp;
                            <IconDelete/>
                            删除
                            &nbsp;&nbsp;
                          </p>
                        </div>
                      </div>
                    </template>
                    <span class="rounded-0 list-item-container"
                          @click="item.isReplyInputVisible = !item.isReplyInputVisible">
                             <IconMessage/> 回复
                   </span>
                  </a-popover>
                </template>
                <a-comment
                    v-for="(nest, index) in item.replyNest" :key="index"
                    :author="nest.user.userName"
                    :datetime="formatDate(new Date(nest.updateTime as string))"
                    align="right"
                >
                  <template #avatar>
                    <a-avatar :size=35 shape="square" @click="toOtherUser(nest?.user.id as number)">
                      <img
                          :src="nest.user.userAvatar"
                          alt="avatar"
                      />
                    </a-avatar>
                  </template>
                  <template #actions>
                    <a-popover class="" trigger="click">
                      <span class="rounded-0 list-item-container">
                       <IconUnorderedList/> 操作
                          </span>
                      <template #content>
                        <div class="list-group p-0">
                          <p class="list-group-item-action list-group-item-light pointer"
                             @click="()=>{message.warning('这个功能懒得写了，建议直接联系管理员~')}">
                            &nbsp;
                            <IconExclamationCircle/>
                            举报
                            &nbsp;&nbsp;
                          </p>
                          <!--需要权限，本人或管理员-->
                          <div
                              v-if="checkAccess(store.state.user.loginUser,ACCESS_ENUM.ADMIN) || nest.user.id == store.state.user.loginUser.id">
                            <p class="list-group-item-action list-group-item-light pointer"
                               @click="doDeleteReplyNest(item.id as any,nest.id as any)">
                              &nbsp;
                              <IconDelete/>
                              删除
                              &nbsp;&nbsp;
                            </p>
                          </div>
                        </div>
                      </template>
                    </a-popover>
                  </template>
                  <template #content>
                    {{ nest.content }}
                  </template>
                </a-comment>
                <span v-if="item.replyNest && (item.replyNest.length < item.replyNestTotal)" class="center">
                  <span class="link-secondary pointer" style="font-size: 15px"
                        @click="loadReplyNest(item.id as number)">加载更多</span>
                </span>
                <a-comment
                    v-if="item.isReplyInputVisible"
                    :avatar="store.state.user.loginUser.userAvatar"
                    align="right"
                >
                  <template #actions>
                    <a-button key="0" type="secondary" @click="item.isReplyInputVisible = false">取消</a-button>
                    <a-button key="1" type="primary" @click="doReplyNest(item.id as number)">回复</a-button>
                  </template>
                  <template #content>
                    <a-input v-model="postReplyNest" :placeholder="`来和${item.user.userName}交流感想吧~`"/>
                  </template>
                </a-comment>
              </a-comment>
            </div>
            <span v-if="(postReplyList.length < replyTotal)" class="center">
                  <span class="link-secondary pointer" style="font-size: 15px" @click="moreReply">加载更多</span>
            </span>
            <a-divider v-else-if="postReplyList && postReplyList.length > 0" :margin="10" class="my-3"><span
                class="text-secondary" style="font-size: 10px">已经到底了~</span></a-divider>
          </div>
        </div>
        <div id="author" class="col-3" style="margin-left: 1.5rem; z-index: 0;">
          <div class="row card shadow align-items-center sticky-top d-none d-xl-block">
            <div class="card-header d-flex flex-column">
              <div class="d-flex text-center">
                <a-avatar :size=60 class="" shape="square" style="margin-right: 0.5rem;"
                          @click="toOtherUser(post.user?.id as number)">
                  <img
                      :src="post.user?.userAvatar"
                      alt="avatar"
                      style="max-width: 150px !important; max-height: 150px !important;"
                  />
                </a-avatar>
                <div class="d-block">
                  <h5 class="name mx-3">{{ post.user?.userName }}</h5>
                  <div class="center my-0">
                <span class="intro ">
                  <!--用户简介-->
                  {{ post.user.userProfile ?? "这个b没有简介" }}
                </span>
                  </div>
                </div>
              </div>
            </div>
            <ul v-if="otherPosts" class="list-group rounded-0 other-post-li p-0 m-0">
              <li class="list-group-item other-post-li-title ">作者分享</li>
              <li v-for="item in otherPosts" :key="item.id"
                  class="list-group-item list-group-item-action text-truncate pointer" @click="toPost(item.id)">
                {{ item.title }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import {defineProps, onMounted, ref, watch, withDefaults} from "vue";
  import {
    PostControllerService,
    PostFavourControllerService,
    PostReplyControllerService,
    PostReplyNestControllerService,
    PostThumbControllerService,
    PostVO,
    type UserVO
  } from "../../../generated";
  import message from "@arco-design/web-vue/es/message";
  import MDViewerPost from "@/components/MDViewerPost.vue";
  import {
    IconDelete,
    IconExclamationCircle,
    IconHeart,
    IconHeartFill,
    IconMessage,
    IconSettings,
    IconShareAlt,
    IconStar,
    IconStarFill,
    IconUnorderedList
  } from "@arco-design/web-vue/es/icon";
  import store from "@/store";
  import formatDate from "@/access/Date";
  import checkAccess from "@/access/checkAccess";
  import ACCESS_ENUM from "@/access/accessEnum";
  import MDViewerForAI from "@/components/MDViewerForAI.vue";
  import {useRouter} from "vue-router";

  const router = useRouter();

  interface Props {
    id: string;
  }

  const props = withDefaults(defineProps<Props>(), {
    id: () => "",
  });

  const post = ref<PostVO>();
  const otherPosts = ref<PostVO[]>();
  const postReplyNest = ref("");

  interface PostReplyVO {
    content?: string;
    createTime?: string;
    id?: number;
    postId?: number;
    updateTime?: string;
    user?: UserVO;
    userId?: number;
    isReplyInputVisible: boolean;
    total: number;
    replyNest: any[];
    replyNestTotal: number;
    replyNestSearchParam: {
      current: number;
      pageSize: number;
      replyId: number;
    };
  }

  const loadReplyNest = async (replyId: number) => {
    const comment = postReplyList.value.find(comment => comment.id === replyId);
    if (!comment) {
      message.error(`Comment with id ${replyId} not found`);
      return;
    }
    const res = await PostReplyNestControllerService.listPostReplyPageUsingPost1({
      current: comment.replyNestSearchParam.current + 1,
      pageSize: 2,
      replyId: replyId,
    });
    // message.success(res)
    if (res.code !== 0) {
      message.error("加载失败", res.message);
    } else {
      if (!comment.replyNest) {
        return;
      }
      // 添加
      comment.replyNest = comment.replyNest.concat(res.data.records);
    }
  };

  const postReplyList = ref<PostReplyVO[]>([]);
  // 监听 props.id 的变化
  watch(() => props.id, async (newId) => {
    // message.info(newId)
    if (newId) {
      await loadData();
    }
  });

  const postReply = ref({
    postId: props.id as any,
    content: "",
  });
  const postReplyQueryRequest = {
    current: 1,
    pageSize: 5,
    postId: props.id as any,
  };
  const replyTotal = ref(0);
  const loadComponents = async () => {
    if (!post.value) return;
    postReplyQueryRequest.postId = props.id as any;
    const res = await PostReplyControllerService.listPostReplyPageUsingPost(postReplyQueryRequest);
    if (res.code == 0) {
      replyTotal.value = res.data.total;
      postReply.value = res.data.records;
      postReplyList.value = postReplyList.value.concat(res.data.records);
      for (const comment of postReplyList.value) {
        comment.isReplyInputVisible = false;
        comment.replyNestSearchParam = {
          current: 1,
          pageSize: 2,
          replyId: comment.id as any,
        };
        const result = await PostReplyNestControllerService.listPostReplyPageUsingPost1(comment.replyNestSearchParam);
        if (result.code == 0) {
          comment.replyNest = result.data.records;
          comment.replyNestTotal = result.data.total;
        }
      }
    } else {
      message.error("加载失败，" + res.message);
    }
  }
  const moreReply = async () => {
    // message.info("加载");
    postReplyQueryRequest.current++;
    await loadComponents();
  }
  const doClear = () => {
    postReply.value.content = "";
  }
  const doReply = async () => {
    postReply.value.postId = props.id as any;
    if (!postReply.value.content) {
      message.error("请输入评论内容");
      return;
    }
    const res = await PostReplyControllerService.addPostReplyUsingPost({
      postId: props.id as any,
      content: postReply.value.content
    });
    if (res.code == 0) {
      postReply.value.content = "";
      // 清空数组
      postReplyList.value = [];
      await loadComponents();
      message.success("评论成功");
      return;
    }
    message.error("评论失败，" + res.message);
  }
  const loadData = async () => {
    const res = await PostControllerService.getPostVoByIdUsingGet(
        props.id as any
    );
    if (res.code === 0) {
      post.value = res.data;
    } else {
      await router.push({
        path: '/404',
        replace: true
      });
      return;
    }
    const otherPostsRes = await PostControllerService.listPostByPageUsingPost({
      userId: res.data?.userId,
      current: 1,
      pageSize: 10,
    })
    if (otherPostsRes.code === 0) {
      otherPosts.value = otherPostsRes.data.records;
    } else {
      message.error("加载失败，" + otherPostsRes.message);
    }
    postReplyList.value = [];
    // 只有当帖子存在时才加载评论
    if (post.value) {
      await loadComponents();
    }
  };

  onMounted(async () => {
    await loadData();
  });
  const toPost = async (id = 1) => {
    await router.push({
      path: `/view/post/${id}`,
    });
    await loadData();
    // message.success("帖子加载成功");
    postReplyList.value = [];
    await loadComponents();
    console.log(postReplyList.value)
    // message.success("回复加载成功");
  };

  const onLikeChange = async (item: PostVO | undefined) => {
    if (!item) {
      return;
    }
    const res = await PostThumbControllerService.doThumbUsingPost({postId: item.id});
    if (res.code !== 0) {
      message.error("点赞失败" + res.message);
      return;
    }
    item.thumbNum = item.thumbNum + (item.hasThumb ? -1 : 1) ?? 0;
    item.hasThumb = !item.hasThumb;
  }
  const onStarChange = async (item: PostVO) => {
    if (!item) {
      message.error("收藏失败，帖子为空");
      return;
    }
    const res = await PostFavourControllerService.doPostFavourUsingPost({postId: item.id});
    if (res.code !== 0) {
      message.error("收藏失败" + res.message);
      return;
    }
    item.favourNum = item.favourNum + (item.hasFavour ? -1 : 1);
    item.hasFavour = !item.hasFavour;
  };
  const doShare = async (post: PostVO) => {
    message.success("复制成功");
    let text = `我在浙纺OJ发现了『 ${post?.title} 』这篇神帖，快来看看: ${window.location.href}`;
    // if (navigator.clipboard && window.isSecureContext) {
    //   await navigator.clipboard.writeText(text);
    // } else {
      // 创建 text area
      const textArea = document.createElement("textarea");
      textArea.value = text;
      // 使 text area 不在 viewport，同时设置不可见
      textArea.style.position = "absolute";
      textArea.style.opacity = "0";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      // // textArea.focus();
      textArea.select();
      // message.info(textArea.innerHTML)
      try {
        const successful = document.execCommand('copy');
        if (!successful) {
          throw new Error('Copy command unsuccessful');
        }
      } catch (err) {
        throw new Error('Copy command failed');
      } finally {
        document.body.removeChild(textArea);
      }
    // }
  }
  // function LogStr (str: string) {
  //   console.log(str);
  //   return str;
  // }
  const doDelete = async (post: PostVO) => {
    const res = await PostControllerService.deletePostUsingPost({id: post.id});
    if (res.code === 0) {
      await router.push({
        path: "/",
      });
      message.success("删除成功");
    } else {
      message.error("删除失败", res.message);
    }
  }

  const doUpdate = (post: PostVO) => {
    router.push({
      path: "/update/post",
      query: {
        id: post.id
      }
    })
  }

  const toOtherUser = (id: number) => {
    router.push({
      path: `/view/other/${id}`,
    });
  }

  const doDeleteReply = async (id: number) => {
    const res = await PostReplyControllerService.deletePostReplyUsingPost({id: id});
    if (res.code === 0) {
      // await loadComponents();
      // 把这条评论删除
      const index = postReplyList.value.findIndex(item => item.id === id);
      if (index !== -1) {
        postReplyList.value.splice(index, 1);
      }
      message.success("删除成功");
    } else {
      message.error("删除失败", res.message);
    }
  }
  const doReplyNest = async (replyId: number) => {
    const res = await PostReplyNestControllerService.addPostNestReplyUsingPost({
      content: postReplyNest.value,
      replyId: replyId,
    });
    if (res.code === 0) {
      postReplyNest.value = "";

      // await loadComponents();
      // await loadReplyNest(replyId);
      const comment = postReplyList.value.find(item => item.id === replyId);
      if (!comment) {
        return;
      }
      // 关闭输入框
      comment.isReplyInputVisible = false;
      // 获取comment当前的数组长度
      let length = comment.replyNest?.length ?? 0;
      length++;
      if (length > 20) {
        length = 20;
      }
      // 再次请求,将结果替换nest数组
      comment.replyNestSearchParam = {
        current: 1,
        pageSize: length,
        replyId: comment.id as any,
      };
      const result = await PostReplyNestControllerService.listPostReplyPageUsingPost1(comment.replyNestSearchParam);
      if (result.code == 0) {
        comment.replyNest = result.data.records;
        comment.replyNestTotal = result.data.total;
      }
      message.success("回复成功");
    } else {
      message.error("回复失败", res.message);
    }
  }
  const doDeleteReplyNest = async (replyId: number, id: number) => {
    try {
      const res = await PostReplyNestControllerService.deletePostReplyUsingPost1({id});
      if (res.code === 0) {
        const comment = postReplyList.value.find(item => item.id === replyId);
        if (!comment) {
          message.error("删除失败，评论已删除");
          return;
        }
        // 更新本地评论列表，移除被删除的子评论
        if (Array.isArray(comment.replyNest)) {
          comment.replyNest = comment.replyNest.filter(nest => nest.id !== id);
          message.success("删除成功");
        } else {
          message.error("删除失败，评论数据异常");
        }
      } else {
        message.error(`删除失败：${res.message}`);
      }
    } catch (e) {
      message.error(`删除失败：${e.message || e}`);
    }
  };
</script>

<style>
  .container img {
    max-width: 100%;
  }

  .name {
    font-family: "Microsoft YaHei", serif;
  }

  .intro {
    font-family: "Microsoft YaHei", serif;
    font-size: 13px;
    color: #6b7280;
  }

  .other-post-li-title {
    /* 渐变 */
    background: linear-gradient(to bottom, #c1e3ff, #fff);
    font-weight: bold;
    font-family: "Microsoft YaHei", serif;
  }

  .pointer {
    cursor: pointer;
  }

  .list-item-container {
    background: none !important;
  }

  .list-item-container:hover {
    background: #f5f5f5 !important;
    cursor: pointer;
  }

  .arco-popover-popup-content {
    padding: 0 !important;
  }
</style>
import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "container mt-5",
  style: {"width":"100vw"}
}
const _hoisted_2 = {
  class: "row w-100 d-flex m-3 align-items-stretch rounded-3 p-3",
  style: {"height":"75vh"}
}
const _hoisted_3 = { class: "col-md-4" }
const _hoisted_4 = { class: "card shadow" }
const _hoisted_5 = { class: "card-body border-0 d-flex flex-column align-items-center justify-content-center p-0" }
const _hoisted_6 = { class: "col-md-4" }
const _hoisted_7 = { class: "card shadow" }
const _hoisted_8 = { class: "card-body d-flex flex-column align-items-center justify-content-center" }
const _hoisted_9 = {
  id: "xiaoFanTuan",
  class: "my-4 text-bold"
}
const _hoisted_10 = { class: "col-md-4" }
const _hoisted_11 = { class: "card shadow" }
const _hoisted_12 = { class: "card-body p-0 d-flex flex-column align-items-center justify-content-center" }

import {ref, watch} from 'vue';
  import message from "@arco-design/web-vue/es/message";

  // 响应式数据
  
export default /*@__PURE__*/_defineComponent({
  __name: 'LuckyView',
  setup(__props) {

  const luckyDog = ref('待定');
  const buttonText = ref('点击抽奖');
  let timer: number;
  let idx: number;
  const participantsInput = ref('');
  const participants = ref<string[]>([]);
  const winners = ref<string[]>([]);

  // 更新参与者列表
  const updateParticipants = () => {
    participants.value = participantsInput.value.split('\n').filter(participant => participant.trim() !== '');
  };

  // 开始抽奖
  const startLottery = () => {
    // 如果空了
    if (participants.value.length === 0) {
      message.error('请输入参与者');
      return;
    }
    clearInterval(timer!);
    timer = setInterval(() => {
      idx = Math.floor(Math.random() * participants.value.length);
      luckyDog.value = participants.value[idx];
    }, 30);
    buttonText.value = '停止滚动';
  };

  // 停止抽奖
  const stopLottery = () => {
    clearInterval(timer!);
    buttonText.value = '继续抽奖';
    // 将当前幸运儿添加到已中奖列表
    winners.value.push(luckyDog.value);
    // 去重
    participants.value.splice(idx, 1);
    // 继续
    startLottery();
  };

  // 切换抽奖状态
  const toggleLottery = () => {
    if (timer) {
      stopLottery();
    } else {
      startLottery();
    }
  };

  document.onkeyup = (e) => {
    if (e.key === 'a' || e.key === 'A') {
      toggleLottery();
    }
  };

  // 监听参与者输入变化
  watch(participantsInput, () => {
    updateParticipants();
  });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "card-header" }, "参与者名单", -1)),
          _createElementVNode("div", _hoisted_5, [
            _withDirectives(_createElementVNode("textarea", {
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((participantsInput).value = $event)),
              class: "rounded-0 form-control h-100",
              placeholder: "每行一个参与者",
              rows: "10",
              style: {"resize":"none"}
            }, null, 512), [
              [_vModelText, participantsInput.value]
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "card-header" }, "幸运大抽奖", -1)),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("h3", _hoisted_9, _toDisplayString(luckyDog.value), 1),
            _createElementVNode("button", {
              class: "btn btn-primary mt-3",
              onClick: toggleLottery
            }, _toDisplayString(buttonText.value), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "card-header" }, "中奖者名单", -1)),
          _createElementVNode("div", _hoisted_12, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(winners.value, (winner, index) => {
              return (_openBlock(), _createElementBlock("span", {
                key: index,
                class: "p-1 list-group-item w-100"
              }, [
                _createElementVNode("span", null, "第" + _toDisplayString(index+1) + "位幸运儿：", 1),
                _createTextVNode(" " + _toDisplayString(winner), 1)
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}
}

})